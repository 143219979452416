@use "../abstracts/" as *;

input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;

  &:checked + label {
    color: #67e8f9;
    border-color: #67e8f9;
    background-color: #0f172a;
  }
  &:invalid {
    border-color: $color-border-error;
  }
}

.radio-button-group {
  .cluster {
    --gap: #{$spacing-3};
  }
  .form-error {
    --space: #{$spacing-1};
    display: none;
  }
  &.hasError {
    .form-error {
      display: block;
    }
    label {
      color: $color-text-error;
      border-color: $color-border-error;
    }
  }
}
