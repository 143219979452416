/*------------------------------------*\
    #VARIABLES
\*------------------------------------*/

/**
 * CONTENTS
 *
 * COLORS
 * Brand Colors...............Globally-available variables and config
 * Neutral Colors.............Grayscale colors, including$color-white and black
 * Utility Colors.............Info, Warning, Error, Success
 *
 * TYPOGRAPHY
 * Font Families..............The fonts used in the design system
 * Sizing.....................Font sizing
 *
 * LAYOUT
 * Max-widths.................Maximum layout container width
 *
 * SPACING
 * Spacing defaults...........Spacing between elements
 *
 * BORDERS
 * Border Width...............Border thicknesses
 * Border Radius..............Border radius definitions
 *
 * ANIMATION
 * Animation Speed............Transition/animation speed variables
 * Animation easing...........Easing variables
 *
 * BREAKPOINTS
 * Breakpoints................Global breakpoint definitions
 */

/*------------------------------------*\
     #COLORS
\*------------------------------------*/

/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/

/**
 * Font Family
 */
$font-primary: "Fira Code", monospace;

/**
 * Font Sizing
 */
$font-size-0: 1rem;
$font-size-1: 1.25rem;
$font-size-2: 1.5625rem;
$font-size-3: 1.9531rem;
$font-size-4: 2.4414rem;
$font-size-5: 3.0518rem;
$font-size-6: 3.8147rem;
$font-size-7: 4.7684rem;
$font-size-8: 5.9605rem;
$font-size-9: 7.4506rem;

/**
 * Font Weight
 */
$font-weight-bold: 700;
$font-weight-regular: 400;

/**
 * Line Height
 */
$line-height-0: 1.5rem;
$line-height-1: 2rem;
$line-height-2: 2rem;
$line-height-3: 2.5rem;
$line-height-4: 3.5rem;
$line-height-5: 4rem;
//  $line-height-6: ;
//  $line-height-7: ;
//  $line-height-8: ;
//  $line-height-9: ;

/**
 * Spacing
 */
$spacing-1: 0.125rem;
$spacing-2: 0.25rem;
$spacing-3: 0.375rem;
$spacing-4: 0.5rem;
$spacing-5: 0.625rem;
$spacing-6: 0.75rem;
$spacing-7: 0.875rem;
$spacing-8: 1rem;
$spacing-9: 1.25rem;
$spacing-10: 1.5rem;
$spacing-11: 1.75rem;
$spacing-12: 2rem;
$spacing-13: 2.25rem;
$spacing-14: 2.5rem;
$spacing-15: 2.75rem;
$spacing-16: 3rem;
$spacing-17: 3.5rem;
$spacing-18: 4rem;
$spacing-19: 5rem;
$spacing-20: 6rem;
$spacing-21: 7rem;
$spacing-22: 8rem;
$spacing-23: 9rem;
$spacing-24: 10rem;
$spacing-25: 11rem;
$spacing-26: 12rem;
$spacing-27: 13rem;
$spacing-28: 14rem;
$spacing-29: 16rem;
$spacing-30: 16rem;
$spacing-31: 18rem;
$spacing-32: 20rem;
$spacing-33: 24rem;

$bp-sm: 375px;
$bp-md: 641px;
$bp-lg: 1008px;

$sm-media: "(max-width: #{$bp-sm})";
$md-media: "(min-width: #{$bp-sm}) and (max-width: #{$bp-md})";
$lg-media: "(min-width: #{$bp-md}) and (max-width: #{$bp-lg})";
$xl-media: "(min-width: #{$bp-lg})";
$sm-md-media: "(max-width: #{$bp-md})";
$md-lg-media: "(min-width: #{$bp-sm}) and (max-width: #{$bp-lg})";
$lg-xl-media: "(min-width: #{$bp-md})";

$font-size-1: 0.625rem;
$font-size-2: 0.75rem;
$font-size-3: 0.875rem;
$font-size-4: 1rem;
$font-size-5: 1.25rem;
$font-size-6: 1.5rem;
$font-size-7: 1.75rem;
$font-size-8: 2rem;
$font-size-9: 2.25rem;
$font-size-10: 2.5rem;
$font-size-11: 2.75rem;
$font-size-12: 3rem;
$font-size-13: 3.5rem;
$font-size-14: 4rem;
$font-size-15: 5rem;
$font-size-16: 6rem;

//animation duration
$transition-short: 300ms;

$max-width-ipsum-form: 25.8rem;
$max-width-ipsum-section: 34rem;
$max-width-loader: 25.8rem;

$border-width-small: 1px;
$border-radius-small: 0.35rem;

$animation-timing-ms-default: 175ms;
$animation-timing-function-standard-easing: cubic-bezier(0.4, 0, 0.2, 1);

$animation-transition-default: $animation-timing-ms-default
  $animation-timing-function-standard-easing;

$mobile-margin: $spacing-6;
$mobile-min-width: 20rem;