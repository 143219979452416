@use "../abstracts/" as *;

fieldset {
  border: none;
  padding: unset;

  legend {
    color: $color-white;
    font-size: $font-size-2;
    @media #{$sm-media} {
      font-size: $font-size-4;
    }
  }
  input, .radio-button-group {
    --space: #{$spacing-4};
  }
}
