@use "../abstracts/" as *;

.cursor::after {
  content: "";
  width: $spacing-8;
  height: $spacing-2;
  background: $color-white;
  display: inline-block;
  animation: cursor-blink 1s steps(2) infinite;
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

.loader {
  --max-width: #{$max-width-loader}; 
}
