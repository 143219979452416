@use "../abstracts/" as *;

header {
    text-align: center;
    padding-block: $spacing-15;
    strong {
        font-size: $font-size-16;
        line-height: 6rem;
        font-weight: $font-weight-bold;
        color: $color-brand-primary-4;

        @media #{$sm-media} {
            line-height: 4.2rem;
            font-size: 4.2rem;
        }
    }  
    span {
        color: $color-white;
        font-size: 1.4rem;
        font-weight: $font-weight-regular;
        @media #{$sm-media} {
            font-size: $font-size-4;
        }
    }
}
