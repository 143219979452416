@use "../abstracts/" as *;

.ipsum-form {
  --space: #{$spacing-9};
  --max-width: #{$max-width-ipsum-form};
  button[type="submit"] {
    --space: #{$spacing-13};
    height: $spacing-17;
    width: $spacing-23;
  }
}
