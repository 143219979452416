/* roboto-100 - latin */
@font-face {
    font-family: 'Monoid';
    font-style: normal;
    font-weight: 100;
    src: url('/assets/fonts/Monoid/Monoid-Regular.ttf') format('truetype');
}
/* roboto-100italic - latin */
@font-face {
    font-family: 'Monoid';
    font-style: italic;
    font-weight: 100;
    src: url('/assets/fonts/Monoid/Monoid-Italic.ttf') format('truetype'); 
}
/* roboto-300 - latin */
@font-face {
    font-family: 'Monoid';
    font-style: bold;
    font-weight: 500;
    src: url('/assets/fonts/Monoid/Monoid-Bold.ttf') format('truetype');  
}