:root {
}

$color-white: white;
$color-black: black;

$color-gray-0: #fafaf9;
$color-gray-1: #f5f5f4;
$color-gray-2: #e7e5e4;
$color-gray-3: #e7e5e4;
$color-gray-4: #a8a29e;
$color-gray-5: #78716c;
$color-gray-6: #57534e;
$color-gray-7: #44403c;
$color-gray-8: #292524;
$color-gray-9: #1c1917;

//brand
$color-brand-primary-0: #eff6ff;
$color-brand-primary-1: #dbeafe;
$color-brand-primary-2: #bfdbfe;
$color-brand-primary-3: #93c5fd;
$color-brand-primary-4: #60a5fa;
$color-brand-primary-5: #3b82f6;
$color-brand-primary-6: #2563eb;
$color-brand-primary-7: #1d4ed8;
$color-brand-primary-8: #1e40af;
$color-brand-primary-9: #1e3a8a;

$color-brand-secondary-0: #fffbeb;
$color-brand-secondary-1: #fef3c7;
$color-brand-secondary-2: #fde68a;
$color-brand-secondary-3: #fcd34d;
$color-brand-secondary-4: #fbbf24;
$color-brand-secondary-5: #f59e0b;
$color-brand-secondary-6: #d97706;
$color-brand-secondary-7: #b45309;
$color-brand-secondary-8: #92400e;
$color-brand-secondary-9: #78350f;

$color-border-focus: $color-brand-primary-5;

$color-text-error: #ef4444;
$color-border-error: #ef4444;

$color-background: #1e293b;
$color-highlight: #67e8f9;

@mixin btn-colors--ghost {
  color: $color-white;
  border: 1px solid $color-white;
  background-color: $color-background;
  padding: $spacing-7;
  font-size: $font-size-3;
  border-radius: $border-radius-small;
  transition: all $animation-transition-default;
}

::selection {
    color: $color-white;
    background: #0f172a;
}
