@use "../abstracts/" as *;

.btn {
  border: $border-width-small solid transparent;
  padding: $spacing-7;
  font-size: $font-size-3;
  @media #{$sm-media} {
     font-size: $font-size-4;
  }
  border-radius: $border-radius-small;
  transition: all $animation-transition-default;
  display: inline-block;

  &--primary {
    background-color: $color-brand-primary-6;
    color: $color-white;

    &:hover {
      background-color: $color-brand-primary-7;
    }
  }

  &--ghost {
    color: $color-white;
    border-color: $color-white;
    background-color: $color-background;

    &:hover,
    &:focus {
      border-color: #67e8f9;
      background-color: #0f172a;
    }

    &:focus {
      color: #67e8f9;
    }
    //for radio buttons 
    &:checked + label {
      color: #67e8f9;
      border-color: #67e8f9;
      background-color: #0f172a;
    }
  }
}
