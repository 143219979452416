@use "../abstracts/" as *;

.stack {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > * {
    margin-block: 0;
  }

  & > * + * {
    margin-block-start: var(--space);
  }
}

.cluster {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
}

.center {
    box-sizing: content-box;
    max-width: var(--max-width);
    margin-inline: auto;
}

