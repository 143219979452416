@use "../abstracts/" as *;

.ipsum-section {
  --max-width: #{$max-width-ipsum-section};
  --space: #{$spacing-3};
  .cluster {
    --gap: #{$spacing-2};
  }

  .ipsum-area {
    --space: #{$spacing-4};
    color: $color-white;

    &.selected {
      background: #0f172a;
    }
    span {
      color: $color-highlight;
    }
  }
}
