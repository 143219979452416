@use "../abstracts/" as *;

input {
  font-family: $font-primary;
  border-radius: $border-radius-small;
  padding: $spacing-8;
  font-size: $font-size-3;
  @media #{$sm-media} {
    font-size: $font-size-4;
  }
  border: $border-width-small solid $color-white;
  background-color: $color-background;
  color: $color-white;
  transition: all $animation-transition-default;
  &:hover {
    border-color: $color-highlight;
  }
  &:focus {
    border-color: $color-highlight;
    outline: none;
    color: $color-highlight;
  }
  &:invalid {
    border-color: $color-border-error;
    color: $color-text-error;
  }
  &:invalid ~ .form-error {
    display: block;
  }
  & ~ .form-error {
    --space: #{$spacing-1};
    display: none;
  }
}

/* ensures the increment/decrement arrows always display */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

